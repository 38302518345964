import React, { useRef, FC, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import HowWeHelpTalentSection from "../../components/HowWeHelpTalentSection/HowWeHelpTalentSection";
import Footer from "../../components/Footer/Footer";
import LookingForNewRolesSection from "../../components/LookingForNewRolesSection/LookingForNewRolesSection";
import { ReviewsSection } from "../HomePage/HomePage";
import { ReactComponent as HrSeperator } from "../../assets/images/HrSeperator.svg";

import "./TalentScreen.scss";
import {
  SmallDownArrow,
  TalentAtWork1,
  TalentAtWork2,
  TalentAtWork3,
  TalentAtWork4,
  TalentHero,
} from "../../assets/images";
import { Link } from "react-router-dom";
import {
  AccessJobs,
  ArrowRight,
  CreateProfile,
  JoinTribe,
  NextArrow,
  WhatYouWillGet1,
  WhatYouWillGet2,
  WhatYouWillGet3,
} from "../../assets/icons";
import RecommendedJob from "../../components/RecommendedJob/RecommendedJob";
import Recourse from "../../components/Recourse/Recourse";
import VerifiedEmployer from "../../components/VerifiedEmployer/VerifiedEmployer";
import transition from "../../utils/transition";
import SignUpCard from "../../components/SignUpCard/SignUpCard";
import {
  recoursesBackground,
  verifiedEmployerBackground,
} from "../../assets/images";
import Slider from "react-slick";

const whatYouWillGetData = [
  {
    icon: <WhatYouWillGet1 />,
    title: "AI Chatbot support, job and resource recommendations",
    description:
      "Tribaja is more than a talent marketplace; we're a platform that cares about your work-life balance. Explore job openings that respect your personal time and prioritize your overall well-being.",
  },
  {
    icon: <WhatYouWillGet2 />,
    title: "Get mentorship and become mentor",
    description:
      "We're dedicated to your career journey. Find workplace opportunities that offer growth, skill development, and a supportive environment to help you reach your full potential.",
  },
  {
    icon: <WhatYouWillGet3 />,
    title: "Verified employer partners",
    description:
      "Our employer verification process ensures that the opportunities you discover come from companies committed to providing a healthy work-life balance and meaningful career progression.",
  },
];

const TalentScreen: FC = () => {
  const [whatYouWillGetIndex, setWhatYouWillGetIndex] = useState(0);
  return (
    <>
      <div className="talentPage">
        <NavBar />
        <section
          className="hero-section"
          style={{
            backgroundImage: `url(${TalentHero})`,
          }}
        >
          <div className="col-1">
            <h1>Embark on a Journey of Exploration, Creation, and Growth</h1>
            <p>
              Get connected to recruiters & a supportive community in tech and
              startup. The best part is, it's absolutely FREE!
            </p>
            <Link className="buttonWithArrow" to="#">
              Sign Up Now
              <ArrowRight />
            </Link>
          </div>
        </section>
        <section className="talent-at-work">
          <h2>Powering Careers: Talent at Work in Leading Companies</h2>
          <div className="images">
            <img src={TalentAtWork1} />
            <img src={TalentAtWork2} />
            <img src={TalentAtWork3} />
            <img src={TalentAtWork4} />
          </div>
        </section>
        <HowWeHelpTalentSection />
        <section className="talentPage__howItWorks">
          <h2>How does it work?</h2>
          <HrSeperator />
          <div className="how-it-works-section">
            <div className="icons__container">
              <CreateProfile />
              <SmallDownArrow />
              <JoinTribe />
              <SmallDownArrow />
              <AccessJobs />
            </div>
            <div className="grid-container">
              <div className="grid-item tranparent"></div>
              <div className="grid-item">
                <div className="svg-wrapper">
                  <CreateProfile />
                </div>
              </div>
              <div className="grid-item horizontal-line">
                <NextArrow />
              </div>
              <div className="grid-item">
                <div className="svg-wrapper">
                  <JoinTribe />
                </div>
              </div>
              <div className="grid-item horizontal-line">
                <NextArrow />
              </div>
              <div className="grid-item">
                <div className="svg-wrapper">
                  <AccessJobs />
                </div>
              </div>
              <div className="grid-item tranparent"></div>
              <div className="colums">
                <p>Create a free profile</p>
                <p>
                  This is an opportunity to showcase who you are. Take your time
                  and make a great impression.
                </p>
              </div>
              <div className="colums">
                <p>Join the tribe</p>
                <p>
                  Joining your tribe means tapping into a platform designed for
                  inclusion in tech, where personalized recommendations and
                  strategic networking match you with partners that provide
                  programs and opportunities.
                </p>
              </div>
              <div className="colums">
                <p>Access jobs & resources</p>
                <p>
                  Tribaja advances professional into careers through our
                  AI-powered platform, which personalizes upskilling programs
                  and matches skills with inclusive workplaces.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="whatYouWillGet">
          <h2>What you will get?</h2>
          <HrSeperator />
          <div className="whatYouWillGet__container">
            {whatYouWillGetData.map((data) => (
              <div key={data.title} className="whatYouWillGet__box">
                {data.icon}
                <div>{data.title}</div>
                <p>{data.description}</p>
              </div>
            ))}
          </div>
          <Slider
            afterChange={(e) => setWhatYouWillGetIndex(e)}
            dots={false}
            slidesToShow={1}
            slidesToScroll={1}
            infinite={true}
            autoplay={true}
            autoplaySpeed={3000}
            className="whatYouWillGet__slider"
          >
            {whatYouWillGetData.map((data) => (
              <div key={data.title} className="whatYouWillGet__box">
                {data.icon}
                <div>{data.title}</div>
                <p>{data.description}</p>
              </div>
            ))}
          </Slider>
          <div className="carousel-dots">
            {whatYouWillGetData.map((_, index) => (
              <button
                key={index}
                className={`carousel-button ${
                  whatYouWillGetIndex === index && "active-carousel-button"
                }`}
              ></button>
            ))}
          </div>
        </section>
        <section className="employers">
          <h2>Verified employers</h2>
          <HrSeperator />
          <div className="employers__container">
            <VerifiedEmployer />
            <VerifiedEmployer />
            <SignUpCard
              paragraph="Sign up and get access to our full range of Jobs"
              backgroundImage={verifiedEmployerBackground}
              dark={false}
              marBottom={false}
              marTop={true}
            />
          </div>
        </section>
        <section className="recourses">
          <h2>Resources</h2>
          <HrSeperator />
          <div className="recourses__container">
            <Recourse />
            <Recourse />
            <SignUpCard
              paragraph="Sign up and gain exclusive access to a wide range of resources"
              backgroundImage={recoursesBackground}
              dark={true}
              marBottom={true}
              marTop={true}
            />
          </div>
        </section>
        <section className="recommended-jobs-section">
          <h2>Recommended Jobs</h2>
          <HrSeperator />
          <div className="recommendedJobs__container">
            <RecommendedJob />
            <RecommendedJob />
            <SignUpCard
              paragraph="Sign up and get access to our full range of Jobs"
              backgroundImage={verifiedEmployerBackground}
              dark={false}
              marBottom={false}
              marTop={true}
            />
          </div>
        </section>

        {/* <ReviewsSection
          title="Reviews"
          paragraph="Join 11,400+ members that are already on their journey to a career in tech."
        />
        <LookingForNewRolesSection /> */}
        {/*

        */}
      </div>
      <Footer />
    </>
  );
};

export default transition(TalentScreen);
