import React, { FC } from "react";
import { ZaraTalent } from "../../assets/images";
import { BestMatchBadge, Experience, OpenFor, Pin } from "../../assets/icons";

import "./OurTalent.scss";

const OurTalent: FC = () => {
  return (
    <div className="ourTalent">
      <div className="ourTalent__profile">
        <img src={ZaraTalent} alt="zara tribaja talent" />
        <div className="content">
          <div>Zara L.</div>
          <span>UI UX Designer at Linkedin</span>
          <BestMatchBadge />
        </div>
      </div>
      <div className="ourTalent__details">
        <div>
          <span>
            <Pin /> From
          </span>
          Sans Francisco, CA
        </div>
        <div>
          <span>
            <Experience />
            Experience
          </span>
          10 Years
        </div>
        <div>
          <span>
            <OpenFor />
            Open for
          </span>
          Full Time Roles
        </div>
      </div>
      <div className="buttons">
        <button>View Profile</button>
        <button className="invert">Contact</button>
      </div>
    </div>
  );
};

export default OurTalent;
