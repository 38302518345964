import React, { FC } from "react";
import { RecourseImage } from "../../assets/images";

import "./Recourse.scss";
import { Bookmark } from "../../assets/icons";

const Recourse: FC = () => {
  return (
    <div className="resource">
      <Bookmark className="resource__bookmark" />
      <img src={RecourseImage} alt="resource" />
      <div className="name">All About The Data</div>
      <div className="tags">
        <span>Training</span>
        <span>Bootcamp</span>
        <span>Workshop/Webinars</span>
        <span>Certification</span>
        <span>Hackathon</span>
      </div>
      <div className="description">
        Aim to create new candidate pools from previously underrepresented
        groups in tech, and our continued efforts to support those candidates
        through admissions (including scholarships), classwork, and on to job
        hunting through mentorship and networking support
      </div>
      <button>Learn more</button>
    </div>
  );
};

export default Recourse;
