import React from "react";
import { Bookmark, Pin, Teams, Verified } from "../../assets/icons";
import { EmployerBackground, Job } from "../../assets/images";

import "./VerifiedEmployer.scss";

const VerifiedEmployer = () => {
  return (
    <div className="verifiedEmployer">
      <div className="verifiedEmployer__bookmark">
        <Bookmark />
      </div>
      <img className="profile" src={Job} alt="employer profile" />
      <div
        className="image"
        style={{
          backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.30), rgba(0, 0, 0, 0.30)), url(${EmployerBackground})`,
        }}
      ></div>
      <div className="verifiedEmployer__content">
        <div className="name">
          Fluent LLC <Verified /> <span>Tribaja Verified</span>
        </div>
        <div className="tags">FINTECH • MOBILE • SOCIAL IMPACT • SOFTWARE </div>
        <div className="details">
          <span>
            <Pin /> Sans Francisco, CA
          </span>
          <span>
            <Teams /> 1000-1500 Employesss
          </span>
        </div>
        <div className="buttons">
          <button>Follow</button>
          <button className="invert">View Profile</button>
        </div>
      </div>
    </div>
  );
};

export default VerifiedEmployer;
