import React, { FC } from "react";

import { Job } from "../../assets/images";
import {
  Bookmark,
  Clock,
  Global,
  Pin,
  Star,
  Verified,
} from "../../assets/icons";

import "./RecommendedJob.scss";
const RecommendedJob: FC = () => {
  return (
    <div className="recommendedJob">
      <div className="recommendedJob__match">
        <div className="progress">82%</div>
        Job Match
      </div>
      <Bookmark className="recommendedJob__bookmark" />
      <img src={Job} alt="job" />
      <div className="name">
        Fluent LLC <Verified />
      </div>
      <div className="rating">
        <Star />
        <Star />
        <Star />
        <Star />
        <Star />
      </div>
      <div className="title">Software Engineer</div>
      <div className="line"></div>
      <div className="details">
        <div>
          <Pin />
          <span>Sans Francisco, CA</span>
        </div>
        <div>
          <Global />
          <span>Full Time Remote</span>
        </div>
        <div>
          <Clock />
          <span>17 Hours Ago</span>
        </div>
      </div>
      <div className="buttons">
        <button>Apply</button>
        <button className="invert">View Job</button>
      </div>
    </div>
  );
};

export default RecommendedJob;
