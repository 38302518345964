import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";

import "./PartnerScreen.scss";
import NavBar from "../../components/NavBar/NavBar";
import { Link } from "react-router-dom";
import {
  FeaturedPrograms,
  HowWeHelpPartners,
  HrSeperator,
  PartnerHero,
  SmallDownArrow,
} from "../../assets/images";
import CompaniesLogos from "../../components/CompaniesLogos/CompaniesLogos";
import {
  AccessCompanies,
  Collaborations1,
  Collaborations2,
  Collaborations3,
  FindTribe,
  HowItWorksPartner1,
  HowItWorksPartner2,
  HowItWorksPartner3,
  NextArrow,
  ThumbsUp,
} from "../../assets/icons";
import Recourse from "../../components/Recourse/Recourse";
import { ReviewsSection } from "../HomePage/HomePage";
import LookingForNewRolesSection from "../../components/LookingForNewRolesSection/LookingForNewRolesSection";
import transition from "../../utils/transition";
import SignUpCard from "../../components/SignUpCard/SignUpCard";
import {
  partner_RecoursesBackground,
  partner_programsBackground,
} from "../../assets/images";
import Slider from "react-slick";

const whatYouWillGetData = [
  {
    icon: <Collaborations1 />,
    title: "Amplified Reach and Exposure",
    description:
      "By partnering with Tribaja, your organization gains access to a wider audience and increased visibility within the tech community.",
  },
  {
    icon: <Collaborations2 />,
    title: "Collective Impact and Network",
    description:
      " Join a collaborative network of like-minded organizations committed to uplifting diverse communities in the tech industry.",
  },
  {
    icon: <Collaborations3 />,
    title: "Upskilling and Empowerment",
    description:
      " Make a tangible difference by offering mentorship, training programs, or educational resources that enable individuals to thrive in the tech industry.",
  },
];

const PartnerScreen = () => {
  const [whatYouWillGetIndex, setWhatYouWillGetIndex] = useState(0);
  return (
    <>
      <div className="partnerScreen">
        <NavBar />
        <section
          className="hero-section"
          style={{
            backgroundImage: `url(${PartnerHero})`,
          }}
        >
          <div className="col-1">
            <h1>Collaborating for Inclusive Tech Education</h1>
            <p>
              Empowering diverse communities through partnerships for upskilling
              and advancement
            </p>
            <div className="buttons">
              <Link to="#">Become a partner</Link>
              <Link className="invert" to="#">
                Add a resource
              </Link>
            </div>
          </div>
        </section>
        <section className="partnerScreen__partnerships">
          <span>Brands we love</span>
          <CompaniesLogos />
        </section>
        <div className="how-we-help-section">
          <h2>How do we help partners?</h2>
          <HrSeperator />
          <div className="help-section-flex">
            <img src={HowWeHelpPartners} alt="how we help" />
            <div className="text">
              <p className="text-title">
                Elevate tech education with Tribaja. Partner with us to uplift
                aspiring professionals, share your expertise, and shape the next
                generation of tech leaders.
              </p>
              <div className="cards">
                <div className="card">
                  <div className="svg-wrapper">
                    <ThumbsUp />
                  </div>
                  <div>
                    <p>Amplify Impact</p>
                    <p>
                      Partnering with Tribaja enables you to amplify your impact
                      in the tech education space. Reach a wider audience of
                      diverse individuals and contribute to their upskilling
                      journey.
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="svg-wrapper">
                    <AccessCompanies />
                  </div>
                  <div>
                    <p>Showcase Your Expertise</p>
                    <p>
                      Leverage our platform to showcase your expertise and
                      educational offerings. Share your courses, resources, and
                      mentorship programs with our community, fostering growth.
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="svg-wrapper">
                    <FindTribe />
                  </div>
                  <div>
                    <p>Shape Future Professionals</p>
                    <p>
                      Join hands with us to shape the future of tech
                      professionals. Your contributions directly influence the
                      skills and knowledge of individuals entering the tech
                      industry, making a lasting impact.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="partnerScreen__howItWorks">
          <h2>How does it work?</h2>
          <HrSeperator />
          <div className="how-it-works-section">
            <div className="icons__container">
              <HowItWorksPartner1 />
              <SmallDownArrow />
              <HowItWorksPartner2 />
              <SmallDownArrow />
              <HowItWorksPartner3 />
            </div>
            <div className="grid-container">
              <div className="grid-item tranparent"></div>
              <div className="grid-item">
                <div className="svg-wrapper">
                  <HowItWorksPartner1 />
                </div>
              </div>
              <div className="grid-item horizontal-line">
                <NextArrow />
              </div>
              <div className="grid-item">
                <div className="svg-wrapper">
                  <HowItWorksPartner2 />
                </div>
              </div>
              <div className="grid-item horizontal-line">
                <NextArrow />
              </div>
              <div className="grid-item">
                <div className="svg-wrapper">
                  <HowItWorksPartner3 />
                </div>
              </div>
              <div className="grid-item tranparent"></div>
              <div className="colums">
                <p>Submit a resource</p>
                <p>
                  This is an opportunity to showcase who you are. Take your time
                  and make a great impression.
                </p>
              </div>
              <div className="colums">
                <p>Get approved</p>
                <p>
                  Ensure your organization meets our standards for inclusivity
                  and commitment to professional growth.
                </p>
              </div>
              <div className="colums">
                <p>Receive new enrollment increases</p>
                <p>
                  Receive new enrollment increases as a verified partner on
                  Tribaja.
                </p>
              </div>
            </div>
          </div>
          <button>Become Partner</button>
        </section>
        <section className="whatYouWillGet">
          <h2>Unlocking Opportunities for Collaboration</h2>
          <HrSeperator />
          <div className="whatYouWillGet__container">
            {whatYouWillGetData.map((data) => (
              <div key={data.title} className="whatYouWillGet__box">
                {data.icon}
                <div>{data.title}</div>
                <p>{data.description}</p>
              </div>
            ))}
          </div>
          <Slider
            afterChange={(e) => setWhatYouWillGetIndex(e)}
            dots={false}
            slidesToShow={1}
            slidesToScroll={1}
            infinite={true}
            autoplay={true}
            autoplaySpeed={3000}
            className="whatYouWillGet__slider"
          >
            {whatYouWillGetData.map((data) => (
              <div key={data.title} className="whatYouWillGet__box">
                {data.icon}
                <div>{data.title}</div>
                <p>{data.description}</p>
              </div>
            ))}
          </Slider>
          <div className="carousel-dots">
            {whatYouWillGetData.map((_, index) => (
              <button
                key={index}
                className={`carousel-button ${
                  whatYouWillGetIndex === index && "active-carousel-button"
                }`}
              ></button>
            ))}
          </div>
        </section>
        <section className="partnerScreen__programs">
          <h2>Our Featured Programs</h2>
          <HrSeperator />
          <p className="paragraph">
            Connecting under supported communities to resources, training, &
            better employment opportunities .
          </p>
          <div className="partnerScreen__programs-container">
            <section>
              <img src={FeaturedPrograms} alt=" feature programs" />
              <p>Path to Power Program</p>
              <button>Learn more</button>
            </section>
            <section className="hide">
              <img src={FeaturedPrograms} alt=" feature programs" />
              <p>Path to Power Program</p>
              <button>Learn more</button>
            </section>

            <SignUpCard
              paragraph="Sign up and get access to our full range of programs"
              backgroundImage={partner_programsBackground}
              dark={false}
              marBottom={false}
              marTop={true}
            />
          </div>
        </section>
        <section className="recourses">
          <h2>List of resources</h2>
          <HrSeperator />
          <div className="recourses__container">
            <Recourse />
            <Recourse />
            <SignUpCard
              paragraph="Sign up and gain exclusive access to a wide range of Resources."
              backgroundImage={partner_RecoursesBackground}
              dark={true}
              marBottom={true}
              marTop={true}
            />
          </div>
        </section>
        {/* <ReviewsSection
          title="What companies are saying"
          paragraph="Join 11,400+ members that are already on their journey to a career in tech."
        />
        <LookingForNewRolesSection /> */}
      </div>
      <Footer />
    </>
  );
};

export default transition(PartnerScreen);
